<script>
import { required, email } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
export default {
  data() {
    return {
      user: { 
              userName: "",  mail: "",  password: "", repassword:"", userGroupId:"",
              name:"", lastName:"", phone:"", country:"", city:"", school:"",
              title:"", section:"", classRoom:"", address:"", fullName:""
            },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      userName: { required },
      mail: { required, email },
      password: { required },
      repassword: { required },
      userGroupId: { required },
      name: { required},
      surName: { required },
      phone: { required },
      school: { required },
      country: { required },
      city: { required },
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "fac") 
        {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.register({
              mail: this.user.mail,
              password: this.user.password,
              repassword:this.user.repassword,
              phone:this.user.phone,
              userGroupId:this.user.userGroupId,
              name:this.user.name,
              surName:this.user.surName,
              country:this.user.country,
              city:this.user.city,
              school:this.user.school,
              title:this.user.title,
              userName:this.user.userName,
              section:this.user.section,
              classRoom:this.user.classRoom,
              address:this.user.address,
              fullName:(this.name+' '+this.lastName),

            })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                  );
                }
              })
              .catch(error => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else 
        {
          console.log(this.user)
          this.registeruser(this.user);
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-light.png" height="90" alt="logo" />
                          </a>
                        </div>
                        <p class="text-muted">Get your free EINSTUDIO account now.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >Registration successfull.</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        >{{regError}}</b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                        
                        <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                          <div class="row">
                            <div class="col-lg-4">

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-users auti-custom-input-icon"></i>
                                <label for="userGroupId">User Group Type</label>
                                <select v-model="user.userGroupId" class="form-control" id="userGroupId" :class="{ 'is-invalid': submitted && $v.user.userGroupId.$error }">
                                  <option value="" selected>Please select one</option>
                                  <option value="2">TEACHER</option>
                                  <option value="3">STUDENT</option>
                                </select>
                                <div v-if="submitted && !$v.user.userGroupId.required" class="invalid-feedback">User group is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-id-card auti-custom-input-icon"></i>
                                <label for="firstname">First Name</label>
                                <input v-model="user.name"
                                  type="text"
                                  class="form-control"
                                  id="firstname"
                                  placeholder="Enter First Name" :class="{ 'is-invalid': submitted && $v.user.name.$error }"/>
                                <div v-if="submitted && !$v.user.name.required" class="invalid-feedback">Firstname is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-id-card auti-custom-input-icon"></i>
                                <label for="lastname">Last Name</label>
                                <input  v-model="user.surName"
                                  type="text"
                                  class="form-control"
                                  id="lastname"
                                  placeholder="Enter Last Name" :class="{ 'is-invalid': submitted && $v.user.surName.$error }"/>
                                <div v-if="submitted && !$v.user.surName.required" class="invalid-feedback">Lastname is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-phone auti-custom-input-icon"></i>
                                <label for="name">Phone</label>
                                <input v-model="user.phone"
                                  type="text"
                                  class="form-control"
                                  id="phone"
                                  placeholder="Enter Last Name" :class="{ 'is-invalid': submitted && $v.user.phone.$error }"/>
                                <div  v-if="submitted && !$v.user.phone.required" class="invalid-feedback">Phone is required.</div>
                              </div>

                            </div>
                            <div class="col-lg-4">

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-globe auti-custom-input-icon"></i>
                                <label for="name">Country</label>
                                <input @input="(val) => (user.country = user.country.toUpperCase())" v-model="user.country"
                                  type="text"
                                  class="form-control"
                                  id="country"
                                  placeholder="Enter Country Name"
                                  maxlength="30"
                                  max="30" :class="{ 'is-invalid': submitted && $v.user.country.$error }" />
                                <div v-if="submitted && !$v.user.country.required" class="invalid-feedback">Country is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-map-marker auti-custom-input-icon"></i>
                                <label for="city">City</label>
                                <input @input="(val) => (user.city = user.city.toUpperCase())"
                                  v-model="user.city"
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  placeholder="Enter City Name"
                                  maxlength="30"
                                  max="30"
                                  :class="{ 'is-invalid': submitted && $v.user.city.$error }" />
                                <div v-if="submitted && !$v.user.city.required" class="invalid-feedback">City is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-school auti-custom-input-icon"></i>
                                <label for="school">School</label>
                                <input @input="(val) => (user.school = user.school.toUpperCase())"
                                  v-model="user.school"
                                  type="text"
                                  class="form-control"
                                  id="school"
                                  placeholder="Enter School Name"
                                  maxlength="30"
                                  max="30" :class="{ 'is-invalid': submitted && $v.user.school.$error }"/>
                                <div v-if="submitted && !$v.user.school.required" class="invalid-feedback">School is required.</div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-id-card auti-custom-input-icon"></i>
                                <label for="title">Title</label>
                                <input @input="(val) => (user.title = user.title.toUpperCase())"
                                  v-model="user.title"
                                  type="text"
                                  class="form-control"
                                  id="title"
                                  placeholder="What is your academic title?"
                                  maxlength="30"
                                  max="30"/>
                              </div>
                              
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group auth-form-group-custom mb-4">
                              <i class="fa fa-user auti-custom-input-icon"></i>
                              <label for="username">Username</label>
                              <input v-model="user.userName"
                                type="text"
                                class="form-control"
                                id="username"
                                maxlength="20"
                                max="20" :class="{ 'is-invalid': submitted && $v.user.userName.$error }" placeholder="Enter username"/>
                              <div v-if="submitted && !$v.user.userName.required" class="invalid-feedback">Username is required.</div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-envelope auti-custom-input-icon"></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.mail"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email" :class="{ 'is-invalid': submitted && $v.user.mail.$error }"/>
                                <div v-if="submitted && $v.user.mail.$error" class="invalid-feedback">
                                  <span v-if="!$v.user.mail.required">Email is required.</span>
                                  <span v-if="!$v.user.mail.mail">Please enter valid email.</span>
                                </div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-lock auti-custom-input-icon"></i>
                                <label for="password">Password</label>
                                <input
                                  v-model="user.password"
                                  type="password"
                                  class="form-control"
                                  id="password"
                                  placeholder="Enter password"
                                  maxlength="10"
                                  minlength="8"
                                  max="10"
                                  min="8" :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
                                <div v-if="submitted && !$v.user.password.required"  class="invalid-feedback">Password is required.</div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-lock auti-custom-input-icon"></i>
                                <label for="userpassword">Confirm Password</label>
                                <input
                                  v-model="user.repassword"
                                  type="password"
                                  class="form-control"
                                  id="repassword"
                                  placeholder="Enter re-password"
                                  maxlength="10"
                                  minlength="8"
                                  max="10"
                                  min="8" :class="{ 'is-invalid': submitted && $v.user.repassword.$error }"/>
                                <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">Password is required.</div>
                              </div>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Register</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Already have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Login</router-link>
                        </p>
                        <p>
                          © 2022 EINSTUDIO.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>